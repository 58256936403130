import { render, staticRenderFns } from "./subscription-permissions.vue?vue&type=template&id=7dfde010&scoped=true&"
import script from "./subscription-permissions.vue?vue&type=script&lang=js&"
export * from "./subscription-permissions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dfde010",
  null
  
)

export default component.exports