<template>
    <div>
        <ValidationObserver ref="observerSubtemp" tag="form" v-slot="{ handleSubmit }" @submit.prevent>
            <b-row align-v="end">
                <b-col cols="6">
                    <dmx-title backlink :bc-items="bcItems">{{ subtemplate.name }}</dmx-title>
                </b-col>
                <b-col cols="6" class="d-flex align-items-end justify-content-end pb-4">
                    <b-button variant="success" @click="handleSubmit(saveSubTemplate())">
                        {{ (subtemplate.id != 0) ? $t('common_save') : $t('common_create') }}
                    </b-button>
                </b-col>
            </b-row>
            <div class="dmx-card mt-3">
                <b-row>
                    <b-col cols="12">
                        <div>
                            <b-row align-v="center" class="mt-2">
                                <b-col v-if="subtemplate.id == 0" sm="6">
                                    <label  class="mb-1">Type</label><small class="text-danger"><em> *</em></small>
                                    <ValidationProvider name="Type" rules="required_dd" v-slot="v">
                                        <b-select v-model="subtemplate.subscriptionType"
                                            :options="subscriptionTypesFormated"></b-select>
                                        <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                                    </ValidationProvider>
                                </b-col>
                                <b-col v-else sm="6">
                                    <label  class="mb-1">Type</label><small class="text-danger"><em> *</em></small>
                                        <b-select v-model="subtemplate.subscriptionType" disabled
                                            :options="subscriptionTypesFormated"></b-select>
                                </b-col>
                                <b-col sm="6">
                                    <label class="mb-1">Currency</label><small class="text-danger"><em> *</em></small>
                                    <ValidationProvider name="Currency" rules="required_dd" v-slot="v">
                                        <b-select v-model="subtemplate.currencyId" :options="currenciesFormated"></b-select>
                                        <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row align-v="center" class="mt-2">
                                <b-col sm="6">
                                    <label class="mb-1">Price</label><small class="text-danger"><em> *</em></small>
                                    <ValidationProvider name="Price" rules="required" v-slot="v">
                                        <b-form-input v-model="subtemplate.price" spellcheck="false" type="number"
                                            autocomplete="off"></b-form-input>
                                        <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                                    </ValidationProvider>
                                    <small class="text-danger" v-if="errorMessage.length > 0"><em>{{ errorMessage
                                    }}</em></small>
                                </b-col>
                                <b-col sm="6">
                                    <label class="mb-1">Name</label><small class="text-danger"><em> *</em></small>
                                    <ValidationProvider name="Name" rules="required" v-slot="v">
                                        <b-form-input v-model="subtemplate.name" spellcheck="false" type="text"
                                            autocomplete="off"></b-form-input>
                                        <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                                    </ValidationProvider>
                                    <small class="text-danger" v-if="errorMessage.length > 0"><em>{{ errorMessage
                                    }}</em></small>
                                </b-col>
                            </b-row>
                            <b-row align-v="center">
                                <b-col sm="12">
                                    <label class="mb-1">Description</label><small class="text-danger"></small>
                                    <b-form-textarea spellcheck="false" type="text" autocomplete="off"
                                        v-model="subtemplate.description"></b-form-textarea>
                                </b-col>
                            </b-row>
                            <b-row align-v="center" class="mt-2">
                                <b-col sm="12">
                                    <label class="mb-1">Permissions</label><small class="text-danger"><em> *</em></small>
                                    <subscription-permissions
                                        :permissions="subtemplate.permissions" @permissionCallback="(ls) => CheckList(ls)"></subscription-permissions>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </ValidationObserver>
    </div>
</template>

<style scoped></style>

<script>
import subscriptionService from '@/services/subscriptionService';
import router from '@/router';
import Multiselect from 'vue-multiselect';
import subPermissionComponent from '@/components/subscriptions/subscription-permissions.vue';

export default {
    components: {
        'subscription-permissions': subPermissionComponent
    },
    name: 'subTemplateEdit',
    props: ["id"],
    data: () => ({
        subtemplate: {
            id: 0,
            name: '',
            description: '',
            subscriptionType: 0,
            currencyId: 0
        },
        currencies: [],
        currenciesFormated: [],
        subscriptionTypes: [],
        subscriptionTypesFormated: [],
        errorMessage: '',
        permissions: [],
    }),
    methods: {
        CheckList(ls){
            this.subtemplate.Permissions = ls;
        },
        saveSubTemplate() {
            this.$refs.observerSubtemp.validate().then(success => {
                if (!success) {
                    return;
                } else {
                    if (this.id > 0) {
                        subscriptionService.updateSubTemplate(this.subtemplate)
                            .then(data => {
                                router.go(-1)
                            });
                    } else {
                        subscriptionService.createSubTemplate(this.subtemplate)
                            .then(data => {
                                router.go(-1)
                            });
                    }
                }
            });
        },
        currenciesList() {
            this.currenciesFormated = [{ value: 0, text: 'Choose currency' }];
            for (let i in this.currencies) {
                this.currenciesFormated.push({ value: this.currencies[i].id, text: this.currencies[i].name });
            }
        },
        subscriptionTypesList() {
            this.subscriptionTypesFormated = [{ value: 0, text: 'Select a Type' }];
            for (let i in this.subscriptionTypes) {
                this.subscriptionTypesFormated.push({ value: this.subscriptionTypes[i].value, text: this.subscriptionTypes[i].name });
            }
        }
    },
    computed: {
        bcItems() {
            return [
                {
                    text: 'SUBSCRIPTION TEMPLATES',
                    href: '/subscription/SubscriptionTemplateList',
                },
                {
                    text: this.subtemplate.name,
                    href: '/subscription/SubTemplateEdit/' + this.subtemplate.id,
                    active: true,
                }
            ]
        },
    },
    mounted() {
        subscriptionService.fetchSubTemplateValues("FetchSubTemplateValues")
            .then((data) => {
                this.currencies = data.currencies;
                this.subscriptionTypes = data.subscriptionTypes;
                this.currenciesList();
                this.subscriptionTypesList();
            })
            .catch(function (error) {
                alert(error);
            });

        if (this.id > 0) {
            subscriptionService.getSubTemplate(this.id)
                .then(data => {
                    this.subtemplate = data;
                });
        }
    },
}
</script>